import React from "react"

const ModulesCustomer = ({ data, datatwo }) => (
  <>
  <section className="container-modulespagescasos">
    <div className="row-modules-pagescasos">
      {data.map(elemento => {
        return (
          <div
            key={elemento.title}
            className="module-pagescasos"
          >
            <div className="modules-pagescasos">
              <div className="modules-pagescasos-img">
                <img
                  loading="lazy"
                  src={elemento.img}
                  className="modules-icon"
                  alt="..."
                />
              </div>
              <p className="title-pagescasos">{elemento.title} </p>
              <p className="body-pagescasos">{elemento.info}</p>
            </div>
          </div>
        )
      })}
    </div>
  </section>
   <p className="text-bodytow-pagescasos ">{datatwo.textbody}</p>
   </>
)
export default ModulesCustomer
